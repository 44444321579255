import React from 'react';
import styled from 'styled-components/macro';
import {
	ButtonBase, Button, Hidden, Typography, Popper, Fade, Paper, ClickAwayListener, MenuList, MenuItem, ListItemIcon, ListItemText, Divider,
	Menu
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import LanguageIcon from '@material-ui/icons/Language';

import { logo } from '../img';
import { ResponsiveMenu, Balance } from './header';
import { subscribeTo, PlayerContainer, AppStateContainer } from '../state';
import { VerticalPadder } from './styled';
import { IconButton, Avatar } from '../component/ui';
import { userItems, menuItems, auMenuItems } from '../data';
import { Link } from '.';
import { isAuOrNz } from '../util';


class Header extends React.Component {

	state = {
		profileMenuAnchor: null,
		languageMenuAnchor: null
	};

	popperOptions = {
		modifiers: {
			offset: {
				offset: '0,10'
			}
		}
	};

	render() {
		const { player, appState } = this.props;

		return (
			<StyledHeader>
				{player.loggedIn() ? this.renderUser() : this.renderAnon()}
				<Hidden xsDown>
					<ResponsiveMenu menuItems={appState.state.auOnly ? auMenuItems : menuItems} />
				</Hidden>
			</StyledHeader>
		);
	}

	renderAnon() {
		const { appState } = this.props;

		return (
			<MainWrapper>
				<div>
					<ButtonBase
						focusRipple
						component={Link}
						to="/"
					>
						<LogoImg
							src={logo}
							alt={appState.state.auOnly ? 'Australian Slots Logo – AU-Slots1.com' : 'Awesome and Unique Slots Logo - AU-Slots1.com'}
						/>
					</ButtonBase>
				</div>
				<div className="flexRow">
					<Hidden xsDown>
						{this.renderLanguageMenu()}
						<VerticalPadder top={0} bottom={0} left={1}>
							<IconButton component={Link} to="/search" aria-label="Search">
								<SearchIcon />
							</IconButton>
						</VerticalPadder>
						<VerticalPadder top={0} bottom={0} left={1}>
							<HeaderButton
								size="small"
								variant="contained"
								color="secondary"
								component={Link}
								to="/login"
							>
								Login
							</HeaderButton>
						</VerticalPadder>
						<VerticalPadder top={0} bottom={0} left={1}>
							<HeaderButton
								size="small"
								variant="contained"
								component='a'
								href="https://www.luckystar.io/blog/welcome-to-lucky-star?a_aid=66f2ee9966220&a_bid=e7658fe3"
							>
								Sign up
							</HeaderButton>
						</VerticalPadder>
					</Hidden>
					<Hidden smUp>
						<VerticalPadder top={0} bottom={0} left={1}>
							<IconButton onClick={appState.showNavigation}>
								<MenuIcon />
							</IconButton>
						</VerticalPadder>
					</Hidden>
				</div>
			</MainWrapper>
		);
	}

	renderUser() {
		const { appState } = this.props;
		const { player } = this.props.player.state;
		const { profileMenuAnchor } = this.state;

		return (
			<MainWrapper>
				<div>
					<ButtonBase
						focusRipple
						component={Link}
						to="/"
					>
						<LogoImg src={logo} alt="Australian Slots Logo – AU-Slots1.com" />
					</ButtonBase>
				</div>
				<Hidden xsDown>
					<div className="center">
						<Balance />
					</div>
				</Hidden>
				<div className="flexRow">
					<Hidden xsDown>
						{this.renderLanguageMenu()}
						<VerticalPadder top={0} bottom={0} left={1}>
							<IconButton component={Link} to="/search" aria-label="Search">
								<SearchIcon />
							</IconButton>
						</VerticalPadder>
						<VerticalPadder top={0} bottom={0} left={1}>
							<IconButton component={Link} to="/cashier" aria-label="Cashier">
								<MonetizationOnIcon />
							</IconButton>
						</VerticalPadder>
						<VerticalPadder top={0} bottom={0} left={1}>
							<IconButton component={Link} to="/profile" aria-label="Profile">
								<AccountCircleIcon />
							</IconButton>
						</VerticalPadder>
						<VerticalPadder top={0} bottom={0} left={1}>
							<PointedAvatar
								alt={player.username}
								src={`https://www.gravatar.com/avatar/${player.emailHash}?s=40`}
								onClick={this.handleAvatarClick}
								aria-label={`Hi ${player.username}`}
								tooltipHide={!!profileMenuAnchor}

							/>
							<Popper
								open={!!profileMenuAnchor}
								anchorEl={profileMenuAnchor}
								transition
								popperOptions={this.popperOptions}
								placement="bottom-end"
							>
								{({ TransitionProps }) => (
								<Fade
										{...TransitionProps}
										timeout={250}
									>
										<Paper>
											<ClickAwayListener onClickAway={this.hideProfileMenu}>
												<div>
													<MenuUsername
														component="p"
														variant="body2"
														noWrap
														align="center"
													>
														<strong>{player.username}</strong>
													</MenuUsername>
													<Divider />
													<MenuList disablePadding dense>
														{userItems.map(this.renderMenuItem)}
													</MenuList>
												</div>
											</ClickAwayListener>
										</Paper>
									</Fade>
								)}
							</Popper>
						</VerticalPadder>
					</Hidden>
					<Hidden smUp>
						<VerticalPadder top={0} bottom={0} left={1}>
							<IconButton onClick={appState.showNavigation}>
								<MenuIcon />
							</IconButton>
						</VerticalPadder>
					</Hidden>
				</div>
			</MainWrapper>
		);
	}

	renderLanguageMenu = () => {
		const { appState } = this.props;

		if (isAuOrNz(appState.state.ipCountry)) {
			return null;
		}

		const { languageMenuAnchor } = this.state;

		return (
			<VerticalPadder top={0} bottom={0} left={1}>
				<IconButton aria-label="Select language" aria-controls="language-menu" aria-haspopup="true" onClick={this.openLangMenu}>
					<LanguageIcon />
				</IconButton>
				<Menu
					id="language-menu"
					anchorEl={languageMenuAnchor}
					keepMounted
					open={!!languageMenuAnchor}
					onClose={this.closeLangMenu}
				>
					<MenuItem dense onClick={this.redirectToRoot} component={'a'} href="/">
						<ListItemText primary="English" />
					</MenuItem>
					<MenuItem dense onClick={this.redirectToAu} component={'a'} href="https://www.au-slots1.com/">
						<ListItemText primary="Australian" />
					</MenuItem>
				</Menu>
			</VerticalPadder>
		);
	};

	renderMenuItem = ({ label, icon, url }) => {
		const menuItemProps = {
			divider: true,
			onClick: this.hideProfileMenu,
			key: url
		};

		if (url) {
			menuItemProps.to = url;
			menuItemProps.component = Link
		}

		return (
			<MenuItem
				{...menuItemProps}
			>
				{icon &&
					<ProfileListIcon>
						{icon}
					</ProfileListIcon>
				}
				<NoPaddingListText
					inset={!icon}
					primaryTypographyProps={{
						variant: "subtitle1"
					}}
				>
					{label}
				</NoPaddingListText>
			</MenuItem>
		);
	}

	handleAvatarClick = (ev) => {
		const { currentTarget } = ev;
		const { profileMenuAnchor } = this.state;

		this.setState({ profileMenuAnchor: profileMenuAnchor === null ? currentTarget : null });
	};

	hideProfileMenu = (ev) => {
		const { profileMenuAnchor } = this.state;

		if (profileMenuAnchor && profileMenuAnchor.contains(ev.target)) {
			return;
		}

		this.setState({ profileMenuAnchor: null });
	};

	openLangMenu = ev => {
		this.setState({ languageMenuAnchor: ev.currentTarget });
	};

	closeLangMenu = ev => {
		this.setState({ languageMenuAnchor: null });
	};

	redirectToRoot = ev => {
		window.location.replace('/');
	};

	redirectToAu = ev => {
		window.location.replace('https://www.au-slots1.com/');
	};

}

const MainWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	position: relative;
	align-items: center;

	& > .center {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	& > .flexRow {
		display: flex;
		align-items: center;
	}
`;

const LogoImg = styled.img`
	width: ${p => p.theme.spacing(25)}px;

	${p => p.theme.mui.breakpoints.down('sm')} {
		width: ${p => p.theme.spacing(18)}px;
	}
`;

const StyledHeader = styled.div`
	padding: ${p => p.theme.spacing()}px ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(.5)}px;
	background: ${p => p.theme.mui.palette.primary.main};

	${p => p.theme.mui.breakpoints.down('xs')} {
		padding: 0 ${p => p.theme.spacing()}px;
	}
`;

const HeaderButton = styled(Button)`
	padding: 5px 6px;
	min-width: ${p => p.theme.spacing(6)}px;
	font-size: ${p => p.theme.pxToRem(12)};
	min-height: ${p => p.theme.spacing(3)}px;

	svg {
		height: ${p => p.theme.spacing(2.5)}px;
		width: ${p => p.theme.spacing(2.5)}px;
		margin-left: ${p => p.variant !== 'flat' ? p.theme.spacing(.5) : 0}px;
	}
`;

const NoPaddingListText = styled(ListItemText)`
	padding-left: 0;
	padding-right: 0;
`;

const ProfileListIcon = styled(ListItemIcon)`
	margin-right: ${p => p.theme.spacing()}px;
	color: ${p => p.theme.mui.palette.primary.main};
`;

const MenuUsername = styled(Typography)`
	max-width: ${p => p.theme.spacing(25)}px;
	padding: ${p => p.theme.spacing()}px;
	font-size: ${p => p.theme.pxToRem(13)};
	cursor: default;
`;

const PointedAvatar = styled(Avatar)`
	cursor: pointer;
`;

export default subscribeTo(
	{
		player: PlayerContainer,
		appState: AppStateContainer
	},
	Header
);
