import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline, Fade } from '@material-ui/core';
import { ThemeProvider } from 'styled-components/macro';
import deepmerge from 'deepmerge';
import { Provider, Subscribe } from 'unstated';
import DateFnsUtils from '@date-io/date-fns';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import Helmet from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';

import {
	Content, SignUp, Home, Casino, Profile, Cashier, LiveCasino,
	BlogNews, NotFound, PasswordRecovery, Logout, VirtualSports, Login,
	Search, ResetPassword, PaymentOptions, BitcoinCasino, ConfirmSignUp, OnlineSlots, OnlinePokies
} from './container';
import {
	Login as LoginComp, Header, Footer, SwipeableMenu, GameOverlay, TawkTo, CheckPlayer,
	ApiMessages, PendingWithdrawalsWidget, LSMessage
} from './component';
import { AppWrapper } from './component/styled';
import { muiThemeCommon, defaultMuiThemeColors } from './theme';
import { PaymentModule } from './submodule';
import { PlayerContainer, PlayerBalanceContainer, AppStateContainer, PaymentWithdrawalsContainer } from './state';
import { inBrowser } from './util';
import { Contact } from './container/Help/';


class App extends React.Component {

	constructor(props) {
		super(props);

		const { isMobile, aAid, aBid, ipCountry = 'AU', requestAuNz = false } = props;

		const muiTheme = props.muiTheme || createMuiTheme(deepmerge(
			defaultMuiThemeColors,
			muiThemeCommon
		));

		this.styledComponentsTheme = {
			mui: muiTheme,
			createTransition: (props = 'all', duration = 'short', easing = 'easeOut', delay = 0) => {
				return muiTheme.transitions.create(props, {
					duration: typeof duration === 'string' ? muiTheme.transitions.duration[duration] : duration,
					easing: typeof easing === 'string' ? muiTheme.transitions.easing[easing] : easing,
					delay
				});
			},
			spacing: (factor = 1) => {
				return muiTheme.spacing.unit * factor;
			},
			pxToRem: muiTheme.typography.pxToRem
		};

		this.playerInitialized = false;
		this.subscribeTo = [ PlayerContainer, AppStateContainer, PlayerBalanceContainer, PaymentWithdrawalsContainer ];

		const appState = new AppStateContainer(isMobile, aAid, aBid, ipCountry, requestAuNz);

		this.providedState = [
			appState
		];
	}

	render() {
		return (
			<Provider inject={this.providedState}>
				<Subscribe to={this.subscribeTo}>{this.renderApp}</Subscribe>
			</Provider>
		);
	}

	renderApp = (playerData, appState, balance, paymentWithdrawals) => {
		const { anonKey, player, playerKey } = playerData.state;

		if (inBrowser) {
			if (anonKey === null) {
				playerData.logout();

				window.location.replace(appState.url('/'));

				return null;
			}

			if (!anonKey || (playerKey && !player)) {
				return null;
			}
		}

		if (player && !this.playerInitialized) {
			this.playerInitialized = true;

			balance.fetch();
			paymentWithdrawals.fetch();
			appState.updateGameProviders();
		}

		const routes = this.renderRoutes(appState);

		return (
			<>
				<CssBaseline />
				<ThemeProvider theme={this.styledComponentsTheme}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Helmet>
							<title></title>
							<meta name="description" content="" />
						</Helmet>
						{inBrowser ? <Router>{routes}</Router> : routes}
						{inBrowser ? <CheckPlayer /> : null}
						{inBrowser ? <PendingWithdrawalsWidget /> : null}
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</>
		);
	};

	renderRoutes = (appState) => {
		return (
			<SnackbarProvider
				autoHideDuration={30000}
				maxSnack={10}
				TransitionComponent={Fade}
				dense={appState.state.isMobile}
			>
				<Switch>
					<Route path="/payment" component={PaymentModule} />
					<Route>
						<AppWrapper>
							<ApiMessages />
							<TawkTo />
							<GameOverlay onClose={appState.unsetGameId} gameId={appState.state.gameId} />
							<LoginComp inDialog />
							<SwipeableMenu />
							<Header />
							<Switch>
								<Route exact path="/" component={Home} />
								<Route path="/sign-up/:refCode?" component={SignUp} />
								<Route path="/auth/register_via_token" component={ConfirmSignUp} />
								<Route path="/profile/:page?" component={Profile} />
								<Route path="/livecasino/:providerId?" component={LiveCasino} />
								<Route path="/blog/:article?/:page?" component={BlogNews} />
								<Route path="/blogs/:article?/:page?" render={({ match, history, location }) => {
									if (appState.state.auOnly) {
										return <Redirect to="/blog/" />
									}

									return (
										<BlogNews match={match} history={history} location={location} />
									);
								}} />
								<Route path="/casino" component={Casino} />
								<Route path="/contact" component={Contact} />
								<Route path="/content" component={Content} />
								<Route path="/cashier/:page?" component={Cashier} />
								<Route path="/logout" component={Logout} />
								<Route path="/login" component={Login} />
								<Route path="/password-recovery" component={PasswordRecovery} />
								<Route path="/recover" component={ResetPassword} />
								<Route path="/search" component={Search} />
								<Route path="/payment-options" component={PaymentOptions} />
								<Route path="/bitcoin-casino" component={BitcoinCasino} />
								{appState.state.auOnly && <Route path="/online-pokies" component={OnlinePokies} />}
								{!appState.state.auOnly && <Route path="/online-slots" component={OnlineSlots} />}
								<Route path="/play/kv/kv-virtualsports" component={VirtualSports} />
								<Route component={NotFound} />
							</Switch>
							<Footer />
							<LSMessage />
						</AppWrapper>
					</Route>
				</Switch>
			</SnackbarProvider>
		);
	};

}

export default App;
