import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Typography, Button, Grid } from '@material-ui/core';
import * as Cookies from 'js-cookie';

import { MaxWidth } from './styled';


const OverlayContainer = styled.article`
	padding: ${p => p.theme.spacing()}px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100;
	box-sizing: border-box;
`;

const MainGrid = styled(Grid)`
	background: rgb(25, 21, 21);
	border: 2px solid rgb(156, 163, 175);
	border-radius: ${p => p.theme.spacing()}px;
	box-sizing: border-box;
`;

const TextContainer = styled(Grid)`
	flex-grow: 1;
	overflox-y: auto;
`;


function LSMessage() {
	const [ hideMsg, setHideMsg ] = useState(Cookies.get('hideLsMsg') === '1');

	if (hideMsg) {
		return null;
	}

	return (
		<OverlayContainer className="p-2 bg-opacity-50 bg-black fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center" style={{ zIndex: 100 }}>
			<MaxWidth factor={100}>
				<MainGrid container spacing={32} alignItems="center" justify="center" direction="column">
					<Grid item>
						<Typography variant="title" component="p" color="textSecondary" align="left">
							Dear Players,
						</Typography>
					</Grid>
					<TextContainer item>
						<Typography variant="subtitle1" component="p" color="textSecondary" align="left" gutterBottom>
							We regret to inform you that our gaming platform will no longer support real money deposits starting from September 19, 2024. If you have a balance on your account, you can still use it to play until November 1, 2024., withdrawals will be possible until October 30, 2024.
						</Typography>
						<Typography variant="subtitle1" component="p" color="textSecondary" align="left" gutterBottom>
							We want to express our heartfelt gratitude for your loyalty and support over the years so we arranged special awards and bonuses on our family-friendly casino Lucky Star. Our team is here to assist you every step of the way, we are committed to making this transition as smooth as possible for you.
						</Typography>
						<Typography variant="subtitle1" component="p" color="textSecondary" align="left" gutterBottom>
							Your Auslots team.
						</Typography>
					</TextContainer>
					<Grid item>
						<Grid container spacing={8} alignItems="center" justify="center">
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									onClick={() => {
										Cookies.set('hideLsMsg', '1', { expires: 1 });
										setHideMsg(true);

										return false;
									}}
								>
									Close
								</Button>
							</Grid>
							<Grid item>
								<Button
									component="a"
									variant="contained"
									color="primary"
									href="https://www.luckystar.io/"
								>
									Join Lucky Star
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</MainGrid>
			</MaxWidth>
		</OverlayContainer>
	);
}

export default LSMessage;